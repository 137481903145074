import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import notificationIcon from "../../../assets/icons/header/notificationIcon.svg"
import './style.scss';
import { request } from 'service';
import endponts from 'service/endponts';
import moment from 'moment';
import NoData from 'component/NoData';
import { Toast } from 'service/toast';

const NotificationDrawer = () => {
    const [openNotification, setOpenNotification] = useState(false);
    const [notificationData, setNotificationData] = useState([]);
    const [userData, setUserData] = useState({})


    const toggleDrawer = (open) => (event) => {
        setOpenNotification(open);
    };

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = () => {
        request({
            url: endponts.Endpoints.viewProfile,
            method: endponts.APIMethods.GET
        }).then((res) => {
            let data = res?.data?.data[0]
            setUserData(data)
        })
    }

    useEffect(() => {
        if (userData?.empId) {
            notificationsForEmployess(userData?.empId);
        }
    }, [userData]);


    const notificationsForEmployess = (empId) => {
        request({
            url: `${endponts.Endpoints.getNotifications}?empId=${empId}`,
            method: endponts.APIMethods.GET
        }).then((response) => {
            let obj = response.data.data.list
            setNotificationData(obj)
        })
    }

    // const clearNotificationsForEmployees = () => {
    //     request({
    //         url: endponts.Endpoints.clearNotifications,
    //         method: endponts.APIMethods.DELETE,
    //     }).then(
    //         toggleDrawer(false)
    //     )
    // }

    const handleRequest = (id, type, senderId) => {

        const approveBody = {
            LetterEnableApproveStatus: "APPROVED",
            status: "RGT Approved",
            Title: "Offer Letter Approval",
            empId: String(senderId)
        }

        const denyBody = {
            LetterEnableApproveStatus: "REJECTED",
            status: "RGT Rejected",
            Title: "Offer Letter Approval",
            empId: String(senderId)
        }

        request({
            url: `${endponts.Endpoints.approveDenyRequest}?notificationId=${id}`,
            method: endponts.APIMethods.POST,
            data: type === 1 ? denyBody : approveBody,
        }).then((res) => {
            if (res.status === 200) {
                let payload = {
                    isRead: 1
                }
                request({
                    url: `${endponts.Endpoints.updateRgtRequest}?id=${id}`,
                    method: endponts.APIMethods.PUT,
                    data: payload,
                }).then((res) => {
                    notificationsForEmployess(userData?.empId)
                })
                Toast({ type: "success", message: `${type === 1 ? "Denied Successfully" : "Approved Successfully"}` })
                setOpenNotification(false)
            }
        })
    }

    const list = () => (
        <Box sx={{ width: 350 }}>
            <List>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='fw-500 fs-20 p-3'>Notifications</div>
                    {/* {
                        notificationData?.length > 0 && <div><button className='but blue-text cursor-pointer' onClick={() => setNotificationData([])}> Clear All</button></div>
                    } */}
                </div>
                {notificationData?.filter((x) => x?.isRead !== 1).length > 0 ? (
                    notificationData
                        .filter((x) => x?.isRead !== 1)
                        .map((x, index) => (
                            <div key={index + "_123"}>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ display: 'block' }}>
                                        <ListItemText
                                            className='fs-16'
                                            primary={x?.Body}
                                        />
                                        {x?.type === "Requesting RGT" && (
                                            <div className='d-flex align-items-center my-3'>
                                                <button
                                                    className='denyReqBtn font-regular-16'
                                                    onClick={() => handleRequest(x?.id, 1, x?.senderId)}
                                                >
                                                    Deny
                                                </button>
                                                <button
                                                    className='approveReqBtn font-regular-16 ms-5'
                                                    onClick={() => handleRequest(x?.id, 2, x?.senderId)}
                                                >
                                                    Approve
                                                </button>
                                            </div>
                                        )}
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </div>
                        ))
                ) : (
                    <NoData />
                )}
            </List>
        </Box>
    );

    return (
        <div className='mx-2'>
            <React.Fragment>
                <Button onClick={toggleDrawer(true)}>
                    <div className="cursor-pointer position-relative">
                        <img width='25px' height='25px' src={notificationIcon} />
                        {notificationData?.filter((x) => x?.isRead === 0).length > 0 && (
                            <div className="notificationCount">
                                {notificationData.filter((x) => x?.isRead === 0).length}
                            </div>
                        )}
                    </div>
                </Button>
                <Drawer
                    anchor={'right'}
                    open={openNotification}
                    onClose={toggleDrawer(false)}
                >
                    {list()}
                </Drawer>
            </React.Fragment>
        </div>
    );
}

export default NotificationDrawer;